import React from 'react';
import CheckoutLayout from '../../components/checkoutLayout';
import { useState, useEffect } from 'react';
import { Link, navigate } from 'gatsby';
import CartSummary from '../../components/cartSummary';

// create a empty gatsby page component
const Checkout = () => {
  // add alert message if customerInfo is not available
  const [alert, setAlert] = useState({
    message: "",
    type: ""
  });
  const [customerInfo, setCustomerInfo] = useState({country: "България"});
  const [willBeDeliveredToAnotherAddress, setWillBeDeliveredToAnotherAddress] = useState(false);
  // create handle checkbox change function
  const handleDeliveryCheckboxChange = () => {
    setWillBeDeliveredToAnotherAddress(!willBeDeliveredToAnotherAddress);
  }
  const [invoiceWanted, setInvoiceWanted] = useState(false);
  const handleInvoiceCheckboxChange = () => {
    setInvoiceWanted(!invoiceWanted);
  }
  // create handle submit function
  const handleSubmit = async (e) => {
    // @TODO: check if preventDefault is necessary
    e.preventDefault();
    if(!Array.from(customerInfo).some(val => val == true)) setAlert({message: "Нещо се обърка.", type: "primary"});
    await navigate('/checkout/confirm/', { state: { customerInfo, willBeDeliveredToAnotherAddress, invoiceWanted } });
    console.log('submit', customerInfo);
  }
  const handleChange = (e) => {
      const { id, value } = e.target;
      setCustomerInfo({ ...customerInfo, [id]: value });
  }
  // create array of all EU contries in bulgarian
  const countries = [
    'България',
    'Германия',
    'Франция',
    'Италия',
    'Испания',
    'Полша',
    'Румъния',
    'Нидерландия',
    'Белгия',
    'Гърция',
    'Чехия',
    'Португалия',
    'Швеция',
    'Унгария',
    'Австрия',
    'Дания',
    'Финландия',
    'Словакия',
    'Ирландия',
    'Хърватия',
    'Литва',
    'Словения',
    'Латвия',
    'Естония',
    'Кипър',
    'Люксембург',
    'Малта'
  ];
  return (<CheckoutLayout>
     <div className='container'>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item active"><a className='text-secondary' href="#">Стъпка 1 - Данни за клиента</a></li>
            <li className="breadcrumb-item text-muted">Стъпка 2</li>
          </ol>
        </nav>
        {/* add alert message if customerInfo is not available */}
        {alert.message && <div className={`alert alert-${alert.type}`} role="alert">
          {alert.message}
        </div>}
        {/* create a client info form: firstName, lastName, email, phone, country (select only from EU), city, postcode, address */}
        <div className='row'>
          <form className='col-lg-5' onChange={handleChange}>
            <div className="form-group">
              <label htmlFor="firstName">Име</label>
              <input type="text" className="form-control" id="firstName" placeholder="Вашето име" required/> 
              <label htmlFor="lastName">Фамилия</label>
              <input type="text" className="form-control" id="lastName" placeholder="Вашата фамилия" required/> 
              <label htmlFor="email">Имейл</label>
              <input type="email" className="form-control" id="email" placeholder="Имейл" required/> 
              <label htmlFor="phone">Телефон</label>
              <input type="phone" className="form-control" id="phone" placeholder="Телефон" required/> 
              <label htmlFor="country">Държава</label>
              <select className="form-control" id="country" selected="България" required>
                {countries.map((country, index) => (<option key={index}>{country}</option>))}
              </select>
              <div className='row'>
                <div className='col-6'>
                  <label htmlFor="city">Град</label>
                  <input type="text" className="form-control" id="city" placeholder="Вашият град" required/>
                </div>
                <div className='col-6'>
                  <label htmlFor="postcode">Пощенски код</label>
                  <input type="text" className="form-control" id="postcode" placeholder="Вашият пощенски код" required/>
                </div>
              </div>
              <label htmlFor="address">Адрес</label>
              <input type="text" className="form-control" id="address" placeholder="Вашият адрес" required/>
            </div>
            {/* create a checkbox for other address */}
            <div className="form-group my-2">
              <div className="form-check">
                <input className="form-check-input " type="checkbox" id="otherAddress" defaultChecked={willBeDeliveredToAnotherAddress} onChange={handleDeliveryCheckboxChange} />
                <label className="form-check-label" htmlFor="otherAddress">Желая поръчката да се достави на ДРУГ адрес</label>
              </div>
            </div>
            {/* create a other address form: city, postcode, address */}
            {willBeDeliveredToAnotherAddress && (<div className="form-group" id="otherAddressForm">
              <label htmlFor="firstName2">Име</label>
              <input type="text" className="form-control" id="firstName2" placeholder="Име" required/> 
              <label htmlFor="lastName2">Фамилия</label>
              <input type="text" className="form-control" id="lastName2" placeholder="Фамилия" required/> 
              <label htmlFor="phone2">Телефон</label>
              <input type="phone" className="form-control" id="phone2" placeholder="Телефон" required/> 
              <label htmlFor="country2">Държава</label>
              <select className="form-control" id="country2" selected="България" required>
                {countries.map((country, index) => (<div key={index}><option>{country}</option></div>))}
              </select>
              <div className='row'>
                  <div className='col-6'>
                    <label htmlFor="city2">Град</label>
                    <input type="text2" className="form-control" id="city2" placeholder="Град" required/>
                  </div>
                  <div className='col-6'>
                    <label htmlFor="postcode2">Пощенски код</label>
                    <input type="text" className="form-control" id="postcode2" placeholder="Пощенски код" required/>
                  </div>
              </div>
              <label htmlFor="address2">Адрес</label>
              <input type="text" className="form-control" id="address2" placeholder="Адрес" required/>
            </div>)}
            {/* create a checkbox for invoice wanted*/}
            <div className="form-group my-2">
              <div className="form-check">
                <input className="form-check-input " type="checkbox" id="invoice" defaultChecked={invoiceWanted} onChange={handleInvoiceCheckboxChange} />
                <label className="form-check-label" htmlFor="invoice">Желая да ми бъде издадена фактура</label>
              </div>
            </div>
            {/* create a invoice form: firm name (required), firmId (required), vatId (optional), city, address */}
            {invoiceWanted && (<div className="form-group" id="invoiceForm">
              <label htmlFor="firmName">Име на фирма</label>
              <input type="text" className="form-control" id="firmName" placeholder="Име на фирма" required/>
              <label htmlFor="firmId">ЕИК</label>
              <input type="text" className="form-control" id="firmId" placeholder="ЕИК" required/>
              <label htmlFor="firmVatId">ИН по ЗДДС</label>
              <input type="text" className="form-control" id="firmVatId" placeholder="ИН по ЗДДС" />
              <label htmlFor="firmCity">Град</label>
              <input type="text" className="form-control" id="firmCity" placeholder="Град" required/>
              <label htmlFor="firmAddress">Адрес</label>
              <input type="text" className="form-control" id="firmAddress" placeholder="Адрес" required/>
            </div>)}
            {/* Create comment form control */}
            <div className="form-group my-5">
              <label htmlFor="comment">Коментар</label>
              <textarea className="form-control" id="comment" rows="2" placeholder="Коментар" />
            </div>
            <Link className="btn" to="/cart">Обратно</Link>
            <button onClick={handleSubmit} type="button" className="btn btn-secondary my-3 text-white">Продължи</button>
          </form>
          <div className="col-7 d-none d-lg-flex">
            <CartSummary/>
          </div>
        </div>
      </div>
  </CheckoutLayout>
  )
}

export default Checkout