import React from "react";
import { useCart } from "react-use-cart";
import { formatPrice } from "../utils";
import { Tooltip } from "bootstrap";
import { useRef, useEffect } from "react";
// create readonly cart summary component
const CartSummary = ({deliveryPrice}) => {
  const {
    isEmpty,
    totalUniqueItems,
    items,
    updateItemQuantity,
    removeItem,
  } = useCart();
  const totalPrice = formatPrice(items.reduce((acc, item) => acc + item.price * item.quantity, 0));
  const allItemsPrice = items.reduce((acc, item) => acc + item.price * item.quantity, 0);
  const totalPriceWithDelivery = deliveryPrice ? formatPrice(allItemsPrice + deliveryPrice) : totalPrice;
  const infoTooltip = useRef(null);
  useEffect(() => {
    new Tooltip(infoTooltip.current);
  });
  return (
          <div className="container text-muted">
            {items.map((item) => (
                <div className="row d-flex" key={item.id}>
                  <div className="col-4">
                    <img className="img-fluid" src={item.product_image_link} alt={item.product_name} />
                  </div>
                  <div className="col-2">
                    <p>{item.product_name}</p>
                  </div>
                  <div className="col-2">
                    <div className="quantity">
                      <p>{item.quantity} бр.</p>
                    </div>
                  </div><div className="col-2">
                    <p className="fw-bold">{formatPrice(item.price)}</p>
                  </div>
                </div>)
                )}
                <br/>
                <div className="container">
                  <div className="row">
                    <div className="col-8 ms-auto">
                    <div className="row">
                      <div className="col-6">
                        <p>Цена:</p>
                      </div>
                      <div className="col-6">
                        <p>{totalPrice}</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <p>Доставка:</p>
                      </div>
                      <div className="col-6">
                        <p className={deliveryPrice ? "d-none" : ""}>няма информация 
                        <span className="px-1" ref={infoTooltip} data-bs-toggle="tooltip" data-bs-placement="bottom" title="Цената за доставка се определя, след като посочите вида на куриерските услуги, които бихте желали да ползвате (в стъпка 2 от поръчката).">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-question-circle" viewBox="0 0 16 16">
                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"/>
                            <path d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"/>
                          </svg>
                        </span>
                        </p>
                        <p className={deliveryPrice ? "" : "d-none"}>{formatPrice(deliveryPrice)}</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <p className="fs-5 fw-bolder">Обща сума:</p>
                      </div>
                      <div className="col-6">
                      <p className="fs-5"><strong className="fw-bolder text-secondary">{totalPriceWithDelivery}</strong></p>
                      </div>
                      </div>
                      </div>
                  </div>
                </div>
            </div>
  );
}

export default CartSummary;